<template>
  <div class='password'>
    <layout-header />
    <main class='password__main base-content-pc'>
      <section class='password__main__left'>
        <select-bar-setting />
      </section>
      <section class='password__main__right'>
        <div class='password__main__right__title'>
          <h3>パスワード変更</h3>
          <button-normal
            @emitClick='execChangePassword()'
            :disabled='judgeDisabledUpdateButton'
            :loading='isLoading.changePassword'
          >
            <p>変更する</p>
          </button-normal>
        </div>
        <form class='password__main__right__input'>
          <h5>現在のパスワード</h5>
          <input-password
            :errorMessage='errorMessage.nowPassword'
            @emitInputText='setNowPassword'
          />
          <h5>新しく設定するパスワード</h5>
          <input-password
            :errorMessage='errorMessage.newPassword'
            @emitInputText='setNewPassword'
          />
          <h5>新しく設定するパスワード(確認)</h5>
          <input-password
            :errorMessage='errorMessage.confirmPassword'
            @emitInputText='setConfirmPassword'
          />
        </form>
      </section>
    </main>
    <layout-footer />
  </div>
</template>
<script>
import LayoutHeader from '@/components/layouts/LayoutHeader.vue';
import LayoutFooter from '@/components/layouts/LayoutFooter.vue';
import ButtonNormal from '@/components/atoms/button/ButtonNormal.vue';
import InputPassword from '@/components/atoms/input/InputPassword.vue';
import SelectBarSetting from '@/components/atoms/select/SelectBarSetting.vue';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    ButtonNormal,
    InputPassword,
    SelectBarSetting,
  },
  data() {
    return {
      isLoading: {
        changePassword: false,
      },
      nowPassword: null,
      newPassword: null,
      confirmPassword: null,
      errorMessage: {
        nowPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
      rule: {
        nowPassword: {
          maxLength: 20,
          minLength: 8,
        },
        newPassword: {
          maxLength: 20,
          minLength: 8,
        },
      },
    };
  },
  computed: {
    judgeDisabledUpdateButton() {
      // エラーメッセージ判定
      if (
        this.errorMessage.nowPassword !== ''
        || this.errorMessage.newPassword !== ''
        || this.errorMessage.confirmPassword !== ''
        || this.nowPassword === ''
        || this.newPassword === ''
        || this.confirmPassword === ''
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    showNoticeBar(title, type, text) {
      this.$notify({
        title,
        text,
        type,
        speed: 400,
        group: 'top-right',
      });
    },
    setNowPassword(nowPassword) {
      this.nowPassword = nowPassword;
    },
    setNewPassword(newPassword) {
      this.newPassword = newPassword;
    },
    setConfirmPassword(confirmPassword) {
      this.confirmPassword = confirmPassword;
    },
    async execChangePassword() {
      try {
        this.isLoading.changePassword = true;
        const changePasswordInfo = {
          nowPassword: this.nowPassword,
          newPassword: this.newPassword,
          newPassword_confirmation: this.confirmPassword,
        };
        await this.$store.dispatch('changePassword', changePasswordInfo);
        this.isLoading.changePassword = false;
        this.showNoticeBar(
          'ユーザー情報の変更を完了しました',
          'success',
        );
      } catch (e) {
        this.showNoticeBar(
          'ユーザー情報の変更に失敗しました',
          'error',
          e.message,
        );
        this.isLoading.changePassword = false;
      }
    },

    validNowPassword(to) {
      if (to !== null) {
        if (to.length > this.rule.nowPassword.maxLength) {
          this.errorMessage.nowPassword = '現在のパスワードの最大文字数を超えています';
        } else if (to.length < this.rule.nowPassword.minLength) {
          this.errorMessage.nowPassword = '現在のパスワードの最小値以下です';
        } else if (to.length === 0) {
          this.errorMessage.nowPassword = '現在のパスワードを入力してください';
        } else if (!to.match(/[A-Z]/)) {
          this.errorMessage.nowPassword = '現在のパスワードに大文字は必須です';
        } else if (!to.match(/[a-z]/)) {
          this.errorMessage.nowPassword = '現在のパスワードに小文字は必須です';
        } else if (!to.match(/[0-9]/)) {
          this.errorMessage.nowPassword = '現在のパスワードに数字は必須です';
        } else {
          this.errorMessage.nowPassword = '';
        }
      }
    },
    validNewPassword(to) {
      if (to !== null) {
        if (to.length > this.rule.newPassword.maxLength) {
          this.errorMessage.newPassword = '新しく設定するパスワードの最大文字数を超えています';
        } else if (to.length < this.rule.newPassword.minLength) {
          this.errorMessage.newPassword = '新しく設定するパスワードの最小値以下です';
        } else if (to.length === 0) {
          this.errorMessage.newPassword = '新しく設定するパスワードを入力してください';
        } else if (!to.match(/[A-Z]/)) {
          this.errorMessage.newPassword = '新しく設定するパスワードに大文字は必須です';
        } else if (!to.match(/[a-z]/)) {
          this.errorMessage.newPassword = '新しく設定するパスワードに小文字は必須です';
        } else if (!to.match(/[0-9]/)) {
          this.errorMessage.newPassword = '新しく設定するパスワードに数字は必須です';
        } else {
          this.errorMessage.newPassword = '';
        }
      }
    },
    validConfirmPassword() {
      if (this.confirmPassword !== null) {
        if (this.confirmPassword !== this.newPassword) {
          this.errorMessage.confirmPassword = 'パスワードが一致していません';
        } else {
          this.errorMessage.confirmPassword = '';
        }
      }
    },
  },
  watch: {
    // リアルタイムバリデーション
    nowPassword: function updateNowPassword(to) {
      this.validNowPassword(to);
    },
    newPassword: function updateNewPassword(to) {
      this.validNewPassword(to);
      this.validConfirmPassword();
    },
    confirmPassword: function updateConfirmPassword() {
      this.validConfirmPassword();
    },
  },
};
</script>
<style lang='scss'>
.password {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.password__main {
  margin: 0 auto 120px;
  display: flex;
  max-width: 100%;
}

.password__main__left {
  width: 25%;
  margin-right: 30px;
}

.password__main__right {
  width: 75%;
  background-color: $color-white;
  padding: 30px;
}

.password__main__right__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  h3 {
    font-size: 24px;
    font-weight: 600;
  }
}

.password__main__right__input,
.password__main__right__introduction {
  h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.password__main__right__input {
  margin-bottom: 5px;
  .input-password:not(:last-child) {
    margin-bottom: 20px;
  }
}

.password__main__right__delete {
  font-size: 12px;
  margin-top: 15px;
  color: $color-dark-gray;
  text-decoration: none;
}

.password__main__right__delete:hover {
  text-decoration: underline;
}
</style>
