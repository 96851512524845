<template>
  <div class='input-password'>
    <input
      type='password'
      @input='emitText'
      v-model='password'
      autocomplete='password'
    />
    <p v-if='errorMessage'>{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  props: {
    errorMessage: String,
  },
  data() {
    return {
      password: '',
    };
  },
  methods: {
    emitText() {
      this.$emit('emitInputText', this.password);
    },
  },
};
</script>

<style lang="scss">
.input-password {
  input {
    background-color: $color-gray;
    border: none;
    border-radius: 3px;
    padding: 8px 10px;
    font-size: 14px;
    outline: none;
    width: 100%;
    max-width: 400px;
  }
  p {
    font-size: 12px;
    color: $color-red;
    margin-top: 2px;
  }
}
</style>
